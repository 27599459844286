// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-case-study-template-tsx": () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/src/templates/caseStudy/template.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import("/Volumes/BeingDrive/Developer/oroboro/internal/website/.cache/data.json")

